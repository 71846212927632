import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
  setDataVerify,
  getJsonUpdate,
} from "@/abstraction/resources/portfolio/portfolioResource";
export default class PortfolioRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexPortfolio"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async store(data) {
    let json = setData(data);
    let response = await axios.post(url("storePortfolio"), json);
    if (response.status === 201) {
      return getJson(response.data.data);
    }
  }

    async delete(portfolioId) {
      await axios.delete(url("destroyPortfolio", { portfolio: portfolioId }));
    }

    async update(portfolioId, data) {
      let json = setData(data);
      let response = await axios.put(url("updatePortfolio", { portfolio: portfolioId }),json);
      if (response.status === 200) {
        return getJsonUpdate(response.data.data);
      }
    }

    async show(portfolioId) {
      let response = await axios.get(url("showPortfolio", { portfolio: portfolioId }));
      if (response.status === 200) {
        return getJson(response.data.data);
      }
    }
    
    async verify(portfolioId,data) {
      const json = setDataVerify(data);
      const response = await axios.put(url("verifyPortfolio", { portfolio: portfolioId }),json);
      if (response.status === 200) {
        return getJsonUpdate(response.data.data);
      }
    }
}
