export const getJson = (data) => ({
  id: data.id,
  name: data.name,
  name_jpn: data.name_jpn,
  country_id: data.country_id,
});

export const getArray = async ({ data }) => {
  let items = data.map((city) => getJson(city));
  return { data: items };
};
