import axios from "axios";
import url from "@/router/url";
import {setData,getJson,setDataResetPass} from "../resources/authResource";

export default class AuthRepository {
    async login(data) {
        let json = setData(data);
        let response = await axios.post(url("login"), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async logout() {
        let response = await axios.post(url("logout"));
        if (response.status === 200) {
            return true;
        }
    }

    async register(data) {
        let json = setData(data);
        let response = await axios.post(url("register"), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async verifyCode (data){
        let json = setData(data);
        let response = await axios.post(url("verifyCode"), json);
        return getJson(response.data.data)
    }

    async forgetPassword (data){
        let json = setData(data);
        let response = await axios.post(url("forgetPassword"), json);
        return getJson(response.data.data)
    }

    async forgotPasswordVerify (data){
        let json = setData(data);
        let response = await axios.post(url("forgetPasswordVerify"), json);
        if(response.status === 200){
            return getJson(response.data.data)
        }
        
    }

    async resetPassword(data) {
        let json = setData(data);
        let response = await axios.post(url("resetPassword"), json);
        if (response.status === 200) {
          return response.data.data;
        }
    }

    async forgetPasswordResendCode (data){
        let json = setDataResetPass(data);
        let response = await axios.post(url("forgetPasswordResendCode"), json);
        return getJson(response.data.data)
    }

    async registerResendCode (data){
        let json = setDataResetPass(data);
        let response = await axios.post(url("registerResendCode"), json);
        return getJson(response.data.data)
    }
}
