import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import {getLanguagesWithLevelArray} from "@/abstraction/resources/modelResource"
const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
export const getJson = (data) => ({
  id: data.id,
  name: data.name,
  first_name: data.first_name,
  last_name: data.last_name,
  email: data.email,
  cell_phone: data.cell_phone,
  thumbnail: data.thumbnail,
  main_image: data.main_image,
  country: data.country,
  corporate: data.corporate,
  description: data.description,
  created_at: new Intl.DateTimeFormat("en-US", options).format(new Date(data.created_at)),
  requestCount: data.userRequests ? data.userRequests.length : 0,
  requestWaitingCount: data.userRequests
    ? data.userRequests.filter((x) => x.status_id === 1).length
    : 0,
  requestApprovedCount: data.userRequests
    ? data.userRequests.filter((x) => x.status_id === 2).length
    : 0,
  requestRejectedCount: data.userRequests
    ? data.userRequests.filter((x) => x.status_id === 3).length
    : 0,
  bio: data.bio,
  bio_jpn: data.bio_jpn,
  birthday: data.birthday,
  city: data.city,
  city_id: data.city_id,
  country_id: data.country_id,
  ethnic: data.ethnic,
  ethnic_id: data.ethnic_id,
  first_name_jpn: data.first_name_jpn,
  gender: data.gender,
  gender_id: data.gender_id,
  image: data.image,
  languagesWithLevel: data.languagesWithLevel
  ? getLanguagesWithLevelArray(data.languagesWithLevel)
  : [],
  last_name_jpn: data.last_name_jpn,
  model_id: data.model_id,
  model_types: data.model_types ? setModelTypesArray(data.model_types) : [],
  name_jpn: data.name_jpn,
  type: data.type,
  userProjects: data.userProjects,
  userRequests: data.userRequests,
});

export const setModelTypesArray = (modelTypes) => {
  if (!modelTypes[0]) return [];
  return modelTypes.map((modelType) => modelType);
};

export const getArray = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((client) => getJson(client));
  return { data, pagination };
};

export const setQuery = (data) => {
  return SetQueriesObject(data);
};

export const setDataClient = (data) => ({
  model_types : data.model_types ? data.model_types.map(modelType => (modelType.id)) : [],
  first_name : `${data.first_name}`,
  last_name : `${data.last_name}`,
  first_name_jpn : `${data.first_name_jpn}`,
  last_name_jpn : `${data.last_name_jpn}`,
  name : `${data.first_name} ${data.last_name}`,
  first_name_length : data.first_name ? data.first_name.length : 0,
  name_jpn : `${data.first_name_jpn??""}${data.last_name_jpn??""}${data.last_name_jpn??""}`,
  first_name_jpn_length : data.first_name_jpn ? data.first_name_jpn.length : 0,
  bio : data.bio,
  bio_jpn : data.bio_jpn,
  city : data.city,
  country_id : data.country_id,
  ethnic_id : data.ethnic_id,
  birthday : data.birthday,
  age : data.age,
  gender_id : data.gender_id,
  cell_phone : data.cell_phone,
  corporate : data.corporate,
  languages : data.languagesWithLevel ? setLanguagesArray(data.languagesWithLevel) : [],
  email : data.email,
})

export const setLanguagesArray = (languages) => {
  let data = [];
  languages.map((language) => {
    if (language.id && language.level_id) {
      data.push(setLanguagesJson(language));
    }
  });
  return data;
};
export const setLanguagesJson = (language) => ({
  id: language.id,
  language_id: language.id,
  level_id: language.level_id,
  level: language.level_id,
});

export const setDataJobOfferLink = (data) => ({
  unique_id: data.unique_id,
  code: data.code,
  modelSelectedType: data.modelSelectedType,
  jobOfferResponses:  data.jobOfferResponses,
  job_offer_id: data.job_offer_id,
});

