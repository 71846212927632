import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
} from "../resources/galleryImageResource";
export default class GalleryImageRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexGallery"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async store(data) {
    let json = setData(data);
    let response = await axios.post(url("storeGallery"), json);
    if (response.status == 200) {
      return getJson(response.data.data);
    }
  }

  async update(galleryId, data) {
    let json = setData(data);
    let response = await axios.put(
      url("updateGallery", { gallery: galleryId }),
      json
    );
    return response.status === 200;
  }

  async delete(galleryId) {
    await axios.delete(url("destroyGallery", { gallery: galleryId }));
  }
}
