export const getJson = (data) => ({
  id: data.id,
  name: data.name,
  color_hex: data.color_hex,
  name_jpn : data.name_jpn
});

export const getArray = async ({ data }) => {
  let items = data.map((hairColor) => getJson(hairColor));
  return { data: items };
};
