export const getJson = (data) => ({
    id: data.id,
    name: data.name,
    name_jpn :data.name_jpn,
    title :data.title,
    title_jpn :data.title_jpn
});

export const getArray = async({ data }) => {
    let items = data.map((gender) => getJson(gender));
    return { data: items };
};
