import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import { getModelJson as getModelJsonModelResources,maxDate } from "./modelResource";
import { getJson as getJsonClientResource } from "./clientResource";
import { objectToFormData } from "@/utils/objectToFormData";
const options = {
  year: 'numeric', 
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};
export const getJson = (data) => ({
  id: data.id,
  user: data.user ? getJsonClientResource(data.user) : {},
  city: data.city,
  city_id: data.city_id,
  jobType: data.jobType,
  model_id: data.model_id,
  user_id: data.user_id,
  description: data.description,
  required_day: data.required_day,
  job_type_id: data.job_type_id,
  file : data.file,
  call_me : !!data.call_me,
  model: data.model ? getModelJson(data.model) : null,
  modelTypes : data.modelTypes ? getArrayModelTypes(data.modelTypes) : [],
  status: data.status
    ? {
      id: data.status.id,
      name: data.status.name,
      style: {
        color: data.status.style.color,
        bgColor: data.status.style.bgColor,
      },
    }
    : {
      name: "Undefined",
      style: {
        color: "white",
        bgColor: "gray",
      },
    },
  created_at: new Intl.DateTimeFormat("en-US", options).format(new Date(data.created_at)),
  begin_date: data.begin_date,
});

export const getArrayModelTypes = (data) => {
  return data.map(modelType =>getJsonModelType(modelType))
}

export const getJsonModelType = (data) => ({
  id: data.id,
  name: data.name,
  name_jpn: data.name_jpn,
})

export const getModelJson = (data) => ({
  bio: data.bio,
  bio_jpn: data.bio_jpn,
  birthday: data.birthday,
  cell_phone: data.cell_phone,
  city: data.city,
  corporate: data.corporate,
  country_id: data.country_id,
  created_at: data.created_at,
  email: data.email,
  ethnic_id: data.ethnic_id,
  first_name: data.first_name,
  first_name_jpn: data.first_name_jpn,
  gender: data.gender ?{
    id:data.gender.id,
    name: data.gender.name,
    name_jpn: data.gender.name_jpn,
  } :{},
  gender_id: data.gender_id,
  id: data.id,
  image: data.image,
  last_name: data.last_name,
  last_name_jpn: data.last_name_jpn,
  model: data.model ? getModelJsonModelResources(data) : {},
  modelProjects: data.modelProjects,
  modelRequests: data.modelRequests?getModelRequestsArray(data.modelRequests):[],
  model_id: data.model_id,
  model_verified_at: data.model_verified_at,
  name: data.name,
  name_jpn: data.name_jpn,
  busyStatus: maxDate(data.modelProjects),
  thumbnail: data.thumbnail,
  type: data.type,
})

export const getModelRequestsArray = (data) => {
  return data.map((request)=>(getModelRequestJson(request)))
}

export const getModelRequestJson = (data) =>({
  begin_date: data.begin_date,
  call_me: data.cell_me ? true : false,
  city: data.city,
  created_at: data.created_at,
  description: data.description,
  id: data.id,
  required_day: data.required_day,
})

export const setData = (data) => {
  let formData = {
    name: data.name,
    language_id: data.language_id,
    image: data.image,
    link: data.link,
    begin_date: data.begin_date,
    required_day: data.required_day,
    call_me: data.call_me ? 1 : 0,
    model_ids: data.model_ids ? data.model_ids : [],
    first_name: data.first_name,
    last_name: data.last_name,
    corporate: data.corporate,
    email: data.email,
    cell_phone: data.cell_phone,
    description: data.description,
    file_batch_id: data.file_batch_id,
    model_types: data.model_types,
    city: data.city
  };
  return objectToFormData(formData);
};

export const getArray = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((request) => getJson(request));
  return { data, pagination };
};

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
