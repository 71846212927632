import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import { objectToFormData } from "@/utils/objectToFormData";
export const getJson = (data) => ({
  id: data.id,
  name: data.name,
  first_name: data.first_name,
  last_name: data.last_name,
  name_jpn: data.name_jpn,
  first_name_jpn: data.first_name_jpn,
  last_name_jpn: data.last_name_jpn,
  bio: data.bio,
  bio_jpn: data.bio_jpn,
  email: data.email,
  birthday: data.birthday,
  cell_phone: data.cell_phone,
  ethnic_id: data.ethnic_id,
  model_id: data.model_id,
  gender_id: data.gender_id,
  city_id: data.city_id,
  country_id: data.country_id,
  corporate: data.corporate,
  created_at: data.created_at,
  joinded_at: new Intl.DateTimeFormat("en").format(new Date(data.created_at)),
  type: data.type,
  thumbnail: data.thumbnail
    ? data.thumbnail
    : "https://via.placeholder.com/600x600",
  joined: data.joined,
});


export const getArray = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((manager) => getJson(manager));
  return { data, pagination };
};

export const setData = (data, isUpdate = false) => {
  let formData = {
    first_name: data.first_name,
    last_name: data.last_name,
    password: data.password,
    password_confirmation: data.password_confirmation,
    email: data.email,
    file_batch_id: data.file_batch_id,
  };
  if (isUpdate) {
    formData["_method"] = "put";
  }
  return objectToFormData(formData);
};

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
