export const getJson = (data) => ({
  id: data.id,
  slug: data.slug,
  name: data.name,
  name_jpn: data.name_jpn,
  description: data.description,
  style:{
    class : data.style.class,
    color : data.style.color,
  }
});

export const getArray = ({ data }) => {
  data = data.map((item) => getJson(item));
  return data;
};

export const setData = (data) => ({
  special_views: data.special_views,
});
