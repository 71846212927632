import axios from "axios";
import url from "@/router/url";
import {
  setData,
  getArray
} from "../resources/attributeResource";
export default class AttributeRepository {
  async index() {
    let response = await axios.get(url("indexAttribute"));
    if (response.status === 200) {
      return getArray(response.data.data);
    }
  }

  async sync(data) {
    let json = setData(data);
    let response = await axios.post(url("syncAttribute"), json);
    if (response.status === 201) {
      // return getJson(response.data.data);
    }
  }

}
