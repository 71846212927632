import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
  setModelVisibility
} from "@/abstraction/resources/modelResource";
import qs from "qs";
export default class ModelRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexModel"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async indexMultipleModel(data) {
    let response = await axios.get(url("indexMultipleModel"), {
      params: { ids: data },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    if (response.status === 200) {
      return getArray(response.data, false);
    }
  }

  async update(data) {
    // let models_types = data.model_types.filter(x => x !== null);
    // console.log(models_types)
    // data.model_types.map(model_type=>{
    //   return model_type.id;
    // })
    let json = setData(data);
    // delete data['attributeValues']
    // delete data['loading']
    // delete data['modelProjects']
    // delete data['modelRequests']
    // delete data['userProjects']
    // delete data['userRequests']
    // delete data['attrValsSwitch']
    // data.items_updated='details';
    // json.appendUpdate=data;
    let response = await axios.post(url("updateModelUser"), json);
    if (response.status === 200) {
      return response.data;
    }
  }

  async updateByAdmin(modelId, data) {
    let json = setData(data);
    let response = await axios.post(
      url("updateModelAdmin", { model: modelId }),
      json
    );
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }

  async acceptUpdateByAdmin(modelId, data) {
    let json = data;
    let response = await axios.post(
      url("acceptUpdateModelAdmin", { model: modelId }),
        {appendUpdate:json}
    );
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }

  async rejectUpdateByAdmin(modelId, data) {
    let json = setData(data);
    let response = await axios.post(
      url("rejectUpdateModelAdmin", { model: modelId }),
      json
    );
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }

  async show(modelId) {
    let response = await axios.get(url("showUser", { user: modelId }));
    if (response.status === 200) {
      let response_json = getJson(response.data.data);
      // if(response_json.model_updated_not_appended!=null && response_json.model_updated_not_appended.items_updated!==false){
        // if(response_json.model_updated_not_appended.model.thumbnail!=null)
          // response_json.model_updated_not_appended.model.thumbnail = response_json.model.thumbnail_review
      // }
      return response_json;
    }
  }

  async updateVisibilityModel(modelId,data) {
    let json = setModelVisibility(data);
    let response = await axios.put(url("updateVisibilityModel",{ model: modelId }), json);
    if (response.status === 200) {
      return true;
    }
  }

  async exportExcel(data,filters) {
    let filter = setQuery(filters) ? setQuery(filters) : "";
    let response = await axios.get(url("exportExcel"),{params : {...data,...filter}});
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async getLinkDownloadImages(user) {
    let response = await axios.get(url("getLinkDownloadImages",{user}));
    if (response.status === 200) {
      return response.data.data;
    }
  }

  async destroyMultipleModels(data) {
    let json = {ids: data.ids,expect: data.expect,}
    let params = setQuery(data) ? setQuery(data) : "";
    let response = await axios.delete(url("destroyMultipleModels"),{data:json,params});
    if (response.status === 200) {
      return true;
    }
  }
}
