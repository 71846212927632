import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
} from "../resources/projectResource";
export default class ProjectRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexProject"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async store(data) {
    let json = setData(data);
    let response = await axios.post(url("storeProject"), json);
    if (response.status === 201) {
      return getJson(response.data.data);
    }
  }

  async update(projectId, data) {
    let json = setData(data);
    let response = await axios.put(
      url("updateProject", { project: projectId }),
      json
    );
    return response.status === 200;
  }

  async delete(projectId) {
    await axios.delete(url("destroyProject", { project: projectId }));
  }
}
