export const setData = (data) => ({
  first_name_jpn: data.first_name_jpn,
  first_name: data.first_name,
  last_name_jpn: data.last_name_jpn,
  last_name: data.last_name,
  email: data.email,
  username: data.username,
  password: data.password,
  password_confirmation: data.password_confirmation,
  code: data.code,
});

export const setDataResetPass = (data) => ({
  email: data.email,
});

export const getJson = (data) => ({
  token: data.token,
  user: data.user
    ? {
      bio: data.user.bio,
      bio_jpn: data.user.bio_jpn,
      birthday: data.user.birthday,
      cell_phone: data.user.cell_phone,
      city_id: data.user.city_id,
      corporate: data.user.corporate,
      country_id: data.user.country_id,
      created_at: data.user.created_at,
      email: data.user.email,
      ethnic_id: data.user.ethnic_id,
      first_name: data.user.first_name,
      first_name_jpn: data.user.first_name_jpn,
      gender_id: data.user.gender_id,
      id: data.user.id,
      image: data.user.image,
      last_name: data.user.last_name,
      last_name_jpn: data.user.last_name_jpn,
      model_id: data.user.model_id,
      model_verified_at: data.user.model_verified_at,
      name: data.user.name,
      name_jpn: data.user.name_jpn,
      thumbnail: data.user.thumbnail,
      type: data.user.type,
    }
    : {},
});
