import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import { getJsonUser } from "@/abstraction/resources/jobOfferResource";
const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  weekday: "long",
  hour: "numeric",
  minute: "numeric",
  hour12: false,
};
const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  weekday: "long",
}
const timeOptions = {
  hour: "numeric",
  minute: "numeric",
  hour12: false,
}
export const getJson = (data) => ({
  id: data.id,
  summary: data.summary,
  created_at: data.created_at ? new Intl.DateTimeFormat("en-US", options).format(
    new Date(data.created_at)
  ) : "",
  created_at_date: data.created_at ? new Intl.DateTimeFormat("en-US", dateOptions).format(
    new Date(data.created_at)
  ) : "",
  created_at_time: data.created_at ? new Intl.DateTimeFormat("en-US", timeOptions).format(
    new Date(data.created_at)
  ) : "",
  summary_jpn: data.summary_jpn,
  text: data.text,
  thumbnail: data.thumbnail ? data.thumbnail : "https://via.placeholder.com/1280x720",
  users: data.users ? getArrayUsers(data.users) : [],
  users_ids: data.users ? data.users.map(({ id }) => id) : [],
  text_jpn: data.text_jpn,
  title: data.title,
  title_jpn: data.title_jpn,
  verify_en: !!data.verify_en,
  verify_jpn: !!data.verify_jpn,
  meta_description: data.meta_description,
  meta_description_jpn: data.meta_description_jpn,
  tags: data.tags,
});

export const getJsonVerify = (data) => ({
  verify_en: !!data.verify_en,
  verify_jpn: !!data.verify_jpn,
  id: data.id
});

export const getArrayUsers = (users) => {
  return users.map((user) => getJsonUser(user));
};

export const getArray = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((brand) => getJson(brand));
  return { data, pagination };
};

export const setData = (data) => ({
  title: data.title,
  summary: data.summary,
  text: data.text,
  summary_jpn: data.summary_jpn,
  text_jpn: data.text_jpn,
  title_jpn: data.title_jpn,
  users: data.users,
  tags: data.tags,
  meta_description: data.meta_description,
  file_batch_id: data.file_batch_id,
});

export const setDataVerify = (data) => {
  const json = {};
  if (data.lang === "en") {
    json.verify_en = data.verify_en ? 0 : 1;
  } else if (data.lang === "jp") {
    json.verify_jpn = data.verify_jpn ? 0 : 1;
  }
  return json;
};

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
