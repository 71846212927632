import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import { getJson as getJsonGender } from "@/abstraction/resources/genderResource";
const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const getAttributeValuesSwitch = (data) => {
  return data.reduce((prev,curr)=>{
    prev[curr.id] = true;
    return prev
  },[])
}

export const getJson = (data) => ({
  id: data.id,
  name: data.name,
  first_name: data.first_name,
  last_name: data.last_name,
  name_jpn: data.name_jpn,
  first_name_jpn: data.first_name_jpn,
  last_name_jpn: data.last_name_jpn,
  attributeValues : data.attributeValues,
  attributeValuesId : data.attributeValues ? data.attributeValues.map(({id})=>(id)) : [],
  attrValsSwitch : data.attributeValues ? getAttributeValuesSwitch(data.attributeValues) : [],
  bio: data.bio,
  bio_jpn: data.bio_jpn,
  email: data.email,
  birthday: data.birthday,
  cell_phone: data.cell_phone,
  ethnic_id: data.ethnic_id,
  ethnic: data.ethnic,
  model_id: data.model_id,
  gender_id: data.gender_id,
  gender: data.gender
    ? {
        id: data.gender.id,
        name: data.gender.name,
        name_jpn: data.gender.name_jpn,
      }
    : {},
  country_id: data.country_id,
  corporate: data.corporate,
  created_at: data.created_at ? new Intl.DateTimeFormat("en-US", options).format(new Date(data.created_at)) : "",
  model_verified_at: data.model_verified_at,
  type: data.type,
  model: getModelJson(data),
  city: data.city,
  country: data.country
    ? {
        id: data.country.id,
        name: data.country.name,
        name_jpn: data.country.name_jpn,
        code: data.country.code,
      }
    : {},
  languagesWithLevel: data.languagesWithLevel
    ? getLanguagesWithLevelArray(data.languagesWithLevel)
    : [],
  model_types: data.model_types ? getModelTypesArray(data.model_types) : [],
  requestCount: data.modelRequests ? data.modelRequests.length : 0,
  projectCount: data.modelProjects ? data.modelProjects.length : 0,
  busyStatus: maxDate(data.model.modelProjects),
  modelProjects: data.modelProjects ? modelProjectArray(data.modelProjects):[],
  modelRequests: data.modelRequests,
  special_views: data.special_views
    ? data.special_views.map((item) => item.id)
    : [],
  unique_id: data.unique_id,
  model_updated_unverified: data.model_updated_unverified,
  model_updated_not_appended: data.model_updated_not_appended==null ? {items_updated:false}:data.model_updated_not_appended,
});

export const modelProjectArray = (data) =>{
  return data.map(project=>getJsonProject(project))
}

export const getJsonProject = (data) =>({
  begin_date: data.begin_date,
  created_at: new Intl.DateTimeFormat("en-US", options).format(new Date(data.created_at)),
  description: data.description,
  end_date: data.end_date,
  fee: data.fee,
  id: data.id,
  name: data.name,
})

export const maxDate = (projects) => {
  if (projects && projects.length) {
    let maxDate = Math.max.apply(
      Math,
      projects.map((x) => new Date(x.end_date))
    );
    maxDate = new Date(maxDate);
    let nowDate = new Date();
    if (maxDate > nowDate) {
      return {
        text: "Busy Until " + new Intl.DateTimeFormat().format(maxDate),
        color: "red",
        bgColor: "bgRed",
      };
    }
  }
  return {
    text: "Available",
    color: "cyan",
    bgColor: "bgCyan",
  };
};

export const getModelJson = (data) => ({
  modelProjectsCount : data.modelProjectsCount,
  is_show_follower_count : data.model.is_show_follower_count,
  is_show_subscribe_count : data.model.is_show_subscribe_count,
  is_hybrid : data.model.is_hybrid === 1,
  id: data.model.id,
  job_experience: data.model.job_experience,
  skills: data.model.skills,
  tattoo: data.model.tattoo,
  job_experience_jpn: data.model.job_experience_jpn,
  skills_jpn: data.model.skills_jpn,
  tattoo_jpn: data.model.tattoo_jpn,
  instagram_id: data.model.instagram_id,
  line_id: data.model.line_id,
  youtube: data.model.youtube,
  address: data.model.address,
  nearest_station: data.model.nearest_station,
  apartment: data.model.apartment,
  zipcode: data.model.zipcode,
  state: data.model.state,
  weight: data.model.weight,
  height: data.model.height,
  bust: data.model.bust,
  waist: data.model.waist,
  hips: data.model.hips,
  shoes: data.model.shoes,
  account_classification_id: data.model.account_classification_id,
  hair_color_id: data.model.hair_color_id,
  eye_color_id: data.model.eye_color_id,
  bank_name: data.model.bank_name,
  branch_name: data.model.branch_name,
  account_no: data.model.account_no,
  account_name: data.model.account_name,
  type_of_visa: data.model.type_of_visa,
  valid_until: data.model.valid_until,
  other: data.model.other,
  status: data.model.status,
  created_at: data.model.created_at,
  image: data.model.image,
  thumbnail: data.model.thumbnail,
  thumbnail_review: data.model.thumbnail_review,
  hair_color: data.model.hair_color
    ? {
        id: data.model.hair_color.id,
        name: data.model.hair_color.name,
        name_jpn: data.model.hair_color.name_jpn,
        color_hex: data.model.hair_color.color_hex,
      }
    : {},
  eye_color: data.model.eye_color
    ? {
        id: data.model.eye_color.id,
        name: data.model.eye_color.name,
        name_jpn: data.model.eye_color.name_jpn,
        color_hex: data.model.eye_color.color_hex,
      }
    : {},
  account_classification: data.model.account_classification
    ? {
        id: data.model.account_classification.id,
        name: data.model.account_classification.name,
        name_jpn: data.model.account_classification.name_jpn,
      }
    : { name: "" },
  gender: data.gender ? getJsonGender(data.gender) : {},
  media: data.model.media ? getMedia(data.model.media) : [],
  galleries: data.model.galleries ? data.model.galleries : [],
});

export const getMediaJson = (data) => ({
  collection_name: data.collection_name,
  file_name: data.file_name,
  id: data.id,
  image: data.image,
  mime_type: data.mime_type,
  pdf_verified_at: data.pdf_verified_at,
  size: data.size,
  thumbnail: data.thumbnail,
  verified_at: data.verified_at,
  thumbnail_w300 : data.thumbnail_w300
});

export const getMedia = (data) => {
  return data.map((item) => getMediaJson(item));
};

export const setModelVisibility = (data) => ({
  is_visible: data.model_verified_at ? 0 : 1,
});
export const setData = (data) => ({
  model: {
    job_experience: data.model.job_experience,
    skills: data.model.skills,
    tattoo: data.model.tattoo,
    job_experience_jpn: data.model.job_experience_jpn,
    tattoo_jpn: data.model.tattoo_jpn,
    skills_jpn: data.model.skills_jpn,
    instagram_id: data.model.instagram_id,
    line_id: data.model.line_id,
    youtube: data.model.youtube,
    address: data.model.address,
    nearest_station: data.model.nearest_station,
    apartment: data.model.apartment,
    zipcode: data.model.zipcode,
    state: data.model.state,
    weight: data.model.weight,
    height: data.model.height,
    bust: data.model.bust,
    waist: data.model.waist,
    hips: data.model.hips,
    shoes: data.model.shoes,
    hair_color_id: data.model.hair_color_id,
    eye_color_id: data.model.eye_color_id,
    account_classification_id: data.model.account_classification_id,
    bank_name: data.model.bank_name,
    branch_name: data.model.branch_name,
    account_no: data.model.account_no,
    account_name: data.model.account_name,
    type_of_visa: data.model.type_of_visa,
    valid_until: data.model.valid_until,
    other: data.model.other,
    is_hybrid: data.model.is_hybrid ? 1 : 0,
    is_show_subscribe_count : data.model.is_show_subscribe_count ? 1 : 0,
    is_show_follower_count : data.model.is_show_follower_count ? 1 : 0,
  },
  model_types: setModelTypesArray(data.model_types),
  attribute_values : data.attributeValuesId,
  first_name: data.first_name,
  last_name: data.last_name,
  first_name_jpn: data.first_name_jpn,
  last_name_jpn: data.last_name_jpn,
  bio: data.bio,
  bio_jpn: data.bio_jpn,
  city: data.city,
  country_id: data.country_id,
  ethnic_id: data.ethnic_id,
  birthday: data.birthday,
  gender_id: data.gender_id,
  cell_phone: data.cell_phone,
  corporate: data.corporate,
  languages: data.languagesWithLevel.length
    ? setLanguagesArray(data.languagesWithLevel)
    : [],
  file_batch_id: data.file_batch_id,
  email: data.email,
});

export const setModelTypesArray = (modelTypes) => {
  if (!modelTypes[0]) return [];
  return modelTypes.map((modelType) =>
    typeof modelType == "object" ? modelType.id : modelType
  );
};

export const setLanguagesJson = (language) => ({
  id: language.id,
  language_id: language.id,
  level_id: language.level_id,
});

export const setLanguagesArray = (languages) => {
  let data = [];
  languages.map((language) => {
    if (language.id && language.level_id) {
      data.push(setLanguagesJson(language));
    }
  });
  return data;
};

export const getModelTypeJson = (modelType) => ({
  id: modelType.id,
  name: modelType.name,
  name_jpn: modelType.name_jpn,
});

export const getLanguageWithLevelJson = (language) => ({
  id: language.id,
  name: language.name,
  name_jpn: language.name_jpn,
  level_id: language.level_id,
  level_name: language.level_name,
  level_name_jpn: language.level_name_jpn,
});

export const getLanguagesWithLevelArray = (languages) => {
  if (!languages.length) {
    return [
      {
        level_id: null,
        id: null,
      },
    ];
  }
  return languages.map((language) => getLanguageWithLevelJson(language));
};

export const getModelTypesArray = (modelTypes) => {
  return modelTypes.map((modelType) => getModelTypeJson(modelType));
};

export const getArray = ({ data, meta }, hasPagination = true) => {
  data = data.map((model) => getJson(model));
  if (hasPagination) {
    const pagination = SetPagination(meta);
    return { data, pagination };
  }
  return data;
};

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
