import axios from "axios";
import url from "@/router/url";
import {
  getJson,
  setData,
  setEmailData,
  setQuery,
  getJsonNotifications,
} from "../resources/userResource";
import {getArrayEmail} from '../resources/jobOfferResource';
import { getArray as  getArrayUser } from "../resources/clientResource";
export default class UserRepository {
  async myInfo() {
    let response = await axios.get(url("indexMyInfo"));
    if (response.status === 200) {
      let response_json = getJson(response.data.data);
      // if(response_json.model_updated_not_appended!=null){
      //   response_json.model_updated_not_appended.isReviewByAdmin = response_json.isReviewByAdmin
      //   if(response_json.model.thumbnail_review!=null)
      //     response_json.model_updated_not_appended.model.thumbnail = response_json.model.thumbnail_review
      //   return response_json.model_updated_not_appended;
      // }
      return response_json;
    }
  }
  async dashboardNotifications() {
    let response = await axios.get(url("dashboardNotifications"));
    if (response.status === 200) {
      return getJsonNotifications(response.data);
    }
  }
  async updateProfile(data) {
    let formData = setData(data);
    let response = await axios.put(url("updateProfile"), formData);
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }
  async verify(userId) {
    let response = await axios.post(url("verifyModel", { model: userId }));
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }
  async appendUpdate(userId) {
    let response = await axios.post(url("appendUpdateModel", { model: userId }));
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }

  async delete(userId) {
    await axios.delete(url("destroyUser", { user: userId }));
  }

  async sendEmailToModels(data) {
    let json = setEmailData(data);
    let params = setQuery(data) ? setQuery(data) : "";
    let response = await axios.post(url("sendEmailToModels"), json, { params });
    if (response.status === 200) {
      return getArrayEmail(response.data.data);
    }
  }

  async sendEmailToClients(data) {
    let json = setEmailData(data);
    let params = setQuery(data) ? setQuery(data) : "";
    let response = await axios.post(url("sendEmailToClients"), json, {
      params,
    });
    if (response.status === 200) {
      return getArrayEmail(response.data.data);
    }
  }

  async sendEmailToUsers(data) {
    let json = setEmailData(data);
    let params = setQuery(data) ? setQuery(data) : "";
    let response = await axios.post(url("sendEmailToUsers"), json, {
      params,
    });
    if (response.status === 200) {
      return getArrayEmail(response.data.data);
    }
  }

  async changeRoll(userId, role) {
    await axios.post(url("changeRole", { user: userId }), { role });
  }

  async exportExcel(params,filter={}) {
    const filters = setQuery(filter);
    const response = await axios.get(url("exportExcel"), { params:{ ...filters,...params } });
    if (response.status === 200) {
      return response.data.data;
    }
  }

  async emailSender({batch}) {
    let response = await axios.post(url("emailSender",{batch}));
    if (response.status === 200) {
      return true;
    }
  }

  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexUsers"), { params });
    if (response.status === 200) {
      return getArrayUser(response.data);
    }
  }

  async updateAttrValues(userId,attribute_values) {
    let response = await axios.put(url("updateAttrValues",{user:userId}),{attribute_values});
    if (response.status === 200) {
      return true;
    }
  }
}
