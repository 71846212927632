import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
} from "../resources/managerResource";
export default class ManagerRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexManager"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async store(data) {
    let json = setData(data);
    let response = await axios.post(url("storeManager"), json);
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }

  async update(managerId, data) {
    let json = setData(data, true);
    let response = await axios.post(
      url("updateUser", { user: managerId }),
      json
    );
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }

  async delete(managerId) {
    await axios.delete(url("destroyUser", { user: managerId }));
  }
}
