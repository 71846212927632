import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
} from "../resources/requestResource";
export default class RequestRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexRequest"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async store(data) {
    let json = setData(data);
    let response = await axios.post(url("storeRequest"), json);
    if (response.status == 201) {
      return getJson(response.data.data);
    }
  }

  async multiStore(data) {
    let json = setData(data);
    let response = await axios.post(url("multiStoreRequest"), json);
    if (response.status == 201) {
      return getJson(response.data.data);
    }
  }

  async delete(requestId) {
    await axios.delete(url("destroyRequest", { request: requestId }));
  }

  async update(requestId, data) {
    let json = setData(data, true);
    let response = await axios.post(
      url("updateRequest", { request: requestId }),
      json
    );
    if (response.status === 200) {
      return response.data.data;
    }
  }

  async updateStatus(requestId, statusId) {
    let response = await axios.post(
      url("updateRequestStatus", { request: requestId, status: statusId })
    );
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }
}
