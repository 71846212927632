import axios from "axios";
import url from "@/router/url";
import { getArray } from "../resources/languageResource";

export default class LanguageRepository {
  async index() {
    let response = await axios.get(url("indexLanguage"));
    if (response.status === 200) {
      return getArray(response.data);
    }
  }
}
