import axios from "axios";
import url from "@/router/url";
import { getJson as getJsonLogin } from "../resources/authResource"
import {
  setQuery,
  getArrayJobOffers,
  setDataJobOffer,
  getJsonJobOffer,
  getArrayOfferResponse,
  getJsonOfferResponse,
  setDataResponseStatus,
  getArrayOfferStatus,
  setDataResponse,
  setDataLinkManager,
  setJobOfferLink,
  getArrayResponse,
  setDataStatusManger,
  setEmailData,
  setDataStatusResponseSelected,
  getJsonStatusClient,
  getArrayEmail
} from "../resources/jobOfferResource";
export default class JobOfferRepository {

  constructor(config = {}) {
    axios.defaults.errorHandle = config.errorHandle;
  }

  async indexJobOffers(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexJobOffers"), { params });
    if (response.status === 200) {
      return getArrayJobOffers(response.data);
    }
  }

  async storeJobOffer(data) {
    let json = setDataJobOffer(data);
    let response = await axios.post(url("storeJobOffer"), json);
    if (response.status === 200) {
      return getJsonJobOffer(response.data.data);
    }
  }

  async storeResponse(data) {
    let json = setDataResponse(data);
    let response = await axios.post(url("storeResponse"), json);
    if (response.status === 200) {
      return getJsonJobOffer(response.data.data);
    }
  }

  async updateJobOffer(jobOffer, data) {
    let json = setDataJobOffer(data);
    let response = await axios.put(url("updateJobOffer", { jobOffer }), json);
    if (response.status === 200) {
      return getJsonJobOffer(response.data.data);
    }
  }

  async showJobOffer(jobOffer) {
    let response = await axios.get(url("showJobOffer", { jobOffer }));
    if (response.status === 200) {
      return getJsonJobOffer(response.data.data);
    }
  }
  async showJobOfferWithUniqueId(uniqueId) {
    let response = await axios.get(url("showJobOfferWithUniqueId", { uniqueId }));
    if (response.status === 200) {
      return getJsonJobOffer(response.data.data);
    }
  }
  async destroyJobOffer(jobOffer) {
    await axios.delete(url("destroyJobOffer", { jobOffer }));
  }

  async destroyJobOfferResponse(jobOffer) {
    await axios.delete(url("destroyJobOfferResponse", { jobOffer }));
  }

  async changeStatusJobOffer(jobOffer, status) {
    let response = await axios.put(url("changeStatusJobOffer", { jobOffer }), { status });
    if (response.status === 200) {
      return getJsonJobOffer(response.data.data);
    }
  }

  async changeStatusManager(jobOffer, data) {
    let json = setDataStatusManger(data);
    let response = await axios.put(url("changeStatusManager", { jobOffer }), json);
    if (response.status === 200) {
      return getJsonJobOffer(response.data.data);
    }
  }

  async changeStatusClient(jobOffer, data) {
    let json = setDataResponseStatus(data);
    let response = await axios.put(url("changeStatusResponseClient", { jobOffer }), json);
    if (response.status === 200) {
      return getJsonStatusClient(response.data.data);
    }
  }

  async indexResponse(jobOfferId, data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexJobOfferResponse", { jobOffer: jobOfferId }), { params });
    if (response.status === 200) {
      return getArrayOfferResponse(response.data);
    }
  }

  async indexResponsesWithUniqueId(uniqueId) {
    try {
      let response = await axios.get(url("indexResponsesWithUniqueId", { uniqueId }));
      if (response.status === 200) {
        return getArrayResponse(response.data.data);
      }
    } catch (error) {
      return error.response.status
    }
  }

  async showJobOfferResponse(jobOffer) {
    let response = await axios.get(url("showJobOfferResponse", { jobOffer }));
    if (response.status === 200) {
      return getJsonOfferResponse(response.data.data);
    }
  }

  async showResponseWithUniqueId(uniqueId) {
    let response = await axios.get(url("showResponseWithUniqueId", { uniqueId }));
    if (response.status === 200) {
      return getJsonJobOffer(response.data.data);
    }
  }

  async changeStatusJobOfferResponse(jobOffer, data) {
    let json = setDataResponseStatus(data);
    let response = await axios.put(url("changeStatusJobOfferResponse", { jobOffer }), json);
    if (response.status === 200) {
      return getJsonOfferResponse(response.data.data);
    }
  }

  async indexStatusOffer() {
    let response = await axios.get(url("indexResponseStatuses"));
    if (response.status === 200) {
      return getArrayOfferStatus(response.data.data);
    }
  }

  async storeLinkManager(data) {
    let json = setDataLinkManager(data);
    let response = await axios.post(url("storeLinkManager"), json);
    if (response.status === 200) {
      return true
    }
  }
  async jobOfferLink() {
    let response = await axios.get(url("jobOfferLink"));
    if (response.status === 200) {
      return getArrayOfferStatus(response.data.data);
    }
  }

  async hasJobOfferLink(uniqueId) {
    let response = await axios.get(url("hasJobOfferLink", { uniqueId }));
    if (response.status === 200) {
      return true;
    }
    return false;
  }

  async loginWithJobOfferLink(data) {
    let json = setJobOfferLink(data);
    let response = await axios.post(url("loginWithJobOfferLink", { uniqueId: data.uniqueId }), json);
    if (response.status === 200) {
      return getJsonLogin(response.data.data)
    }
  }

  async sendEmailToResponses(jobOffer, data) {
    let json = setEmailData(data);
    let params = setQuery(data) ? setQuery(data) : "";
    let response = await axios.post(url("sendEmailToResponses", { jobOffer }), json, { params });
    if (response.status === 200) {
      return getArrayEmail(response.data.data);
    }
  }

  async changeStatusSelected(data) {
    let json = setDataStatusResponseSelected(data);
    let params = setQuery(data) ? setQuery(data) : "";
    let response = await axios.put(url("changeStatusSelected", { jobOffer: data.job_offer_id }), json, { params });
    if (response.status === 200) {
      return getJsonOfferResponse(response.data.data);
    }
  }
}
