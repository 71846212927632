import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
} from "../resources/brandResource";
export default class BrandRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexBrand"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async store(data) {
    let json = setData(data);
    let response = await axios.post(url("storeBrand"), json);
    if (response.status === 201) {
      return getJson(response.data.data);
    }
  }
  async delete(brandId) {
    await axios.delete(url("destroyBrand", { brand: brandId }));
  }

  async update(brandId, data) {
    let json = setData(data, true);
    let response = await axios.post(
      url("updateBrand", { brand: brandId }),
      json
    );
    if (response.status === 200) {
      return response.data.data;
    }
  }
}
