import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
export const getJson = (data) => ({
  id: data.id,
  image: data.image,
});

export const getArray = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((gallery) => getJson(gallery));
  return { data, pagination };
};

export const setData = (data) => ({
  id: data.id,
  image: data.image,
});

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
