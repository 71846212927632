<template>
    <v-app>
        <v-overlay :opacity="opacity" :value="overlay" color="white" :dark="false">
        </v-overlay>
        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>
<script>
  import {mapActions} from 'vuex';
  import {TokenStorage, UserStorage} from "./utils/storage";

  export default {
        methods: {
            ...mapActions('user', ['loadMyInfo', 'loadNotifications'])
        },
      data: () => ({
        overlay: true,
        opacity: 1,
      }),
      mounted() {
        window.addEventListener('load', () => {

          let fadeEffect = setInterval( () => {
            if (!this.opacity) {
              this.opacity = 1;
            }
            if (this.opacity > 0) {
              this.opacity -= 0.1;
            } else {
              clearInterval(fadeEffect);
              this.overlay = false;
            }
          }, 50);
        });
      },
      created() {
            if (TokenStorage.getToken()) {
                this.loadMyInfo();
            }
            if (TokenStorage.getToken() && UserStorage.get() && UserStorage.get().type === "manager") {
                this.loadNotifications()
            }
        }
    }
</script>
<style>
    img {
        max-width: 100%;
    }
</style>
