import axios from "axios";
import url from "@/router/url";
import { getArray, getJson, setQuery , setDataJobOfferLink, setDataClient } from "../resources/clientResource";
export default class ClientRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexClient"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async storeJobOfferLink(data) {
    let json = setDataJobOfferLink(data);
    let response = await axios.post(url("storeJobOfferLink"), json);
    if (response.status === 200) {
      return true;
    }
  }

  async createClient(data) {
    let json = setDataClient(data);
    let response = await axios.post(url("createClient"), json);
    if (response.status === 200) {
      return getJson(response.data.data)
    }
  }

  async update(clientId, data) {
    let json = setDataClient(data);
    let response = await axios.put(url("updateUser", { user: clientId }),json);
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }

  async indexModelSelection(jobOffer,data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexModelSelection",{jobOffer}), { params });
    if (response.status === 200) {
      // return getArray(response.data);
    }
  } 
  
  async changeStatusModelSelection(modelId) {
    let response = await axios.post(url("changeStatusModelSelection",),{modelId});
    if (response.status === 200) {
      // return getJson(response.data.data);
    }
  }
  
}
