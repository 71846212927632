export const getJson = (data) => ({
    id: data.id,
    name: data.name,
    name_jpn : data.name_jpn
  });
  
  export const getArray = async ({ data }) => {
    let items = data.map((modelType) => getJson(modelType));
    return { data: items };
  };
  