import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
const options = {
    weekday: 'long',
    year: 'numeric', 
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
export const getJson = (data) => ({
    created_at: new Intl.DateTimeFormat("en-US", options).format(new Date(data.created_at)),
    id: data.id,
    is_systematic: !!data.is_systematic,
    message: data.message,
    title: data.title,
    slug : data.slug,
});

export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta); 
    data = data.map((brand) => getJson(brand));
    return { data, pagination };
};

export const setData = (data) => ({
    title : data.title,
    message : data.message
});
export const setQuery = (data) => {
    return SetQueriesObject(data);
};
