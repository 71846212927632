export const getJson = (data) => ({
    id: data.id,
    name: data.name,
    code: data.code,
    name_jpn: data.name_jpn,
  });
  
  export const getArray = async ({ data }) => {
    let items = data.map((country) => getJson(country));
    return { data: items };
  };