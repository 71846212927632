export const getJson = (data) => ({
    id : data.id,
    name : data.name,
    name_jpn: data.name_jpn,
    attributeValues: data.attributeValues ? getArrayAttrValues(data.attributeValues) : [],
    combo : data.attributeValues && data.attributeValues.length ? getJsonCombo(data.attributeValues) : [] ,
    key : Math.random().toString(36).substring(2,7),
});

export const getArray = ( data ) => {
    const attributes = data.map((attr) => getJson(attr));
    return attributes;
};

export const setData = (data) => ({
  attributes: data.attributes ? setAttrData(data.attributes) : {},
});
export const setAttrData = (data) => {
  return data.map((attr) => ({
    id: attr.id,
    name: attr.name,
    attributeValues: setAttrValues(attr.attributeValues),
  }));
};

export const getArrayAttrValues = (data) => {
    return data.map(attr=>(getJsonAttrValues(attr)))
};

export const getJsonAttrValues = (data) => ({
    id: data.id,
    name: data.name,
    name_jpn: data.name_jpn,
    key: Math.random().toString(36).substring(2,7),
});

export const getJsonCombo = (data) => {
  return data.map(attr=>(attr.name))
}

export const setAttrValues = (data) => {
  return data.map((val) => ({ name: val.name, id: val.id ? val.id : null }));
};
