import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
  setDataVerify,
  getJsonVerify
} from "@/abstraction/resources/blog/blogResource";
export default class BlogRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexBlog"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async store(data) {
    let json = setData(data);
    let response = await axios.post(url("storeBlog"), json);
    if (response.status === 201) {
      return getJson(response.data.data);
    }
  }

    async delete(blogId) {
      await axios.delete(url("destroyBlog", { blog: blogId }));
    }

    async update(blogId, data) {
      let json = setData(data);
      let response = await axios.put(url("updateBlog", { blog: blogId }),json);
      if (response.status === 200) {
        return getJson(response.data.data);
      }
    }

    async show(blogId) {
      let response = await axios.get(url("showBlog", { blog: blogId }));
      if (response.status === 200) {
        return getJson(response.data.data);
      }
    }
    
    async verify(blogId,data) {
      const json = setDataVerify(data);
      const response = await axios.put(url("verifyBlog", { blog: blogId }),json);
      if (response.status === 200) {
        return getJsonVerify(response.data.data);
      }
    }
}
