import { setLanguagesArray,setModelTypesArray } from "./modelResource";
import { SetQueriesObject } from "@/utils/setQueriesObject";
import { getModelTypesArray } from "./modelResource"

export const getJson = (data) => ({
    id: data.id,
    api_token: data.api_token,
    name: data.name,
    first_name: data.first_name,
    last_name: data.last_name,
    name_jpn: data.name_jpn,
    first_name_jpn: data.first_name_jpn,
    last_name_jpn: data.last_name_jpn,
    bio: data.bio,
    bio_jpn: data.bio_jpn,
    email: data.email,
    birthday: data.birthday,
    cell_phone: data.cell_phone,
    ethnic_id: data.ethnic_id,
    model_id: data.model_id,
    gender_id: data.gender_id,
    is_model: data.is_model,
    city_id: data.city_id,
    country_id: data.country_id,
    corporate: data.corporate,
    created_at: data.created_at,
    model_verified_at: data.model_verified_at,
    type: data.type,
    model: data.model
        ? {
            id: data.model.id,
            job_experience: data.model.job_experience,
            skills: data.model.skills,
            tattoo: data.model.tattoo,
            job_experience_jpn: data.model.job_experience_jpn,
            skills_jpn: data.model.skills_jpn,
            tattoo_jpn: data.model.tattoo_jpn,
            instagram_id: data.model.instagram_id,
            line_id: data.model.line_id,
            youtube: data.model.youtube,
            address: data.model.address,
            nearest_station: data.model.nearest_station,
            apartment: data.model.apartment,
            zipcode: data.model.zipcode,
            is_show_follower_count : data.model.is_show_follower_count === 1 ? true : false,
            is_show_subscribe_count : data.model.is_show_subscribe_count === 1 ? true : false,
            is_hybrid : data.model.is_hybrid === 1 ? true : false,
            state: data.model.state,
            weight: data.model.weight,
            height: data.model.height,
            bust: data.model.bust,
            waist: data.model.waist,
            hips: data.model.hips,
            shoes: data.model.shoes,
            account_classification_id: data.model.account_classification_id,
            hair_color_id: data.model.hair_color_id,
            eye_color_id: data.model.eye_color_id,
            bank_name: data.model.bank_name,
            branch_name: data.model.branch_name,
            account_no: data.model.account_no,
            account_name: data.model.account_name,
            type_of_visa: data.model.type_of_visa,
            valid_until: data.model.valid_until,
            other: data.model.other,
            status: data.model.status,
            created_at: data.model.created_at,
            image: data.model.image,
            thumbnail: data.model.thumbnail
                ? data.model.thumbnail
                : "https://via.placeholder.com/600x600",
            thumbnail_review: data.model.thumbnail_review
                ? data.model.thumbnail_review
                : "https://via.placeholder.com/600x600",
            hair_color: data.model.hair_color
                ? {
                    id: data.model.hair_color.id,
                    name: data.model.hair_color.name,
                    name_jpn: data.model.hair_color.name_jpn,
                    color_hex: data.model.hair_color.color_hex,
                }
                : {},
            eye_color: data.model.eye_color
                ? {
                    id: data.model.eye_color.id,
                    name: data.model.eye_color.name,
                    name_jpn: data.model.eye_color.name_jpn,
                    color_hex: data.model.eye_color.color_hex,
                }
                : {},
            account_classification: data.model.account_classification
                ? {
                    id: data.model.account_classification.id,
                    name: data.model.account_classification.name,
                    name_jpn: data.model.account_classification.name_jpn,
                }
                : {},
            media: data.model.media,
            galleries: data.model.galleries,
        }
        : {
            job_experience: null,
            skills: null,
        },
    city: data.city,
    country: data.country
        ? {
            id: data.country.id,
            name: data.country.name,
            name_jpn: data.country.name_jpn,
            code: data.country.code,
        }
        : {},
    languagesWithLevel: getLanguagesWithLevelArray(data.languagesWithLevel),
    model_types: getModelTypesArray(data.model_types),
    modelProjects: data.modelProjects,
    userProjects: data.userProjects,
    modelRequests: data.modelRequests,
    userRequests: data.userRequests,
    // model_updated_not_appended: data.model_updated_not_appended,
    // item_details: data.item_details,
    // isReviewByAdmin: data.isReviewByAdmin
});


export const getLanguageWithLevelJson = (language) => ({
    id: language.id,
    name: language.level_id,
    name_jpn: language.name_jpn,
    level_id: language.level_id,
    level_name: language.level_name,
    level_name_jpn: language.level_name_jpn,
});

export const setData = (data) => ({
    first_name: data.first_name,
    last_name: data.last_name,
    first_name_jpn: data.first_name_jpn,
    last_name_jpn: data.last_name_jpn,
    bio: data.bio,
    bio_jpn: data.bio_jpn,
    city_id: data.city_id,
    city: data.city,
    country_id: data.country_id,
    ethnic_id: data.ethnic_id,
    birthday: data.birthday,
    gender_id: data.gender_id,
    cell_phone: data.cell_phone,
    corporate: data.corporate,
    model_types: setModelTypesArray(data.model_types),
    languages: data.languagesWithLevel && data.languagesWithLevel.length
        ? setLanguagesArray(data.languagesWithLevel)
        : [],
    email: data.email,
});

export const setEmailData = (data) => {
    const email = {
        ids: data.ids,
        expect: data.expect,
    }
    if(data.email_template.id === -1 ){
        email.message= data.message;
        email.subject= data.subject;
    }else{
        email.email_template_id = data.email_template.id;
    }
    return email
}


export const getLanguagesWithLevelArray = (languages) => {
    if (!languages.length) return [{ id: null , level_id:null }];
    return languages.map((language) => getLanguageWithLevelJson(language));
};

export const getJsonNotifications = (data) => ({
    contacts: data.contacts,
    clients: data.clients,
    models: data.models,
    request: data.request
});

export const setQuery = (data) => {
    return SetQueriesObject(data);
};
