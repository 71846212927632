import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import { getJsonUser } from "@/abstraction/resources/jobOfferResource";
import { getJson as getJsonClient } from "@/abstraction/resources/clientResource";
export const getJson = (data) => ({
  created_at: data.created_at ? new Intl.DateTimeFormat("en-US").format(
    new Date(data.created_at) 
  ): "",
  client: data.client ? getJsonClient(data.client) : {},
  client_id: data.client_id,
  galleries: data.galleries,
  id: data.id,
  image: data.image,
  location: data.location,
  location_jpn: data.location_jpn,
  project: data.project ? getJsonProject(data.project) : {},
  meta_description: data.meta_description,
  meta_description_jpn: data.meta_description_jpn,
  project_date: data.project_date,
  project_id: data.project_id,
  summary: data.summary,
  summary_jpn: data.summary_jpn,
  thumbnail: data.thumbnail ? data.thumbnail : "https://via.placeholder.com/1280x720",
  title: data.title,
  title_jpn: data.title_jpn,
  users: data.users ? data.users.map(({id})=>(id)):[],
  users_object: data.users ? getArrayUsers(data.users) : [],
  users_number : data.users ? data.users.length : 0,
  verify_en: data.verify_en,
  verify_jpn: data.verify_jpn,
  video_link: data.video_link,
  tags: data.tags,
});

export const getJsonProject = (data) => ({
  begin_date: data.begin_date,
  created_at: data.created_at,
  description: data.description,
  end_date: data.end_date,
  fee: data.fee,
  id: data.id,
  name: data.name,
});

export const getJsonUpdate = (data) => ({
  id: data.id,
  location: data.location,
  location_jpn: data.location_jpn,
  summary: data.summary,
  summary_jpn: data.summary_jpn,
  title: data.title,
  title_jpn: data.title_jpn,
  verify_en: data.verify_en,
  verify_jpn: data.verify_jpn,
});

export const getArrayUsers = (users) => {
  return users.map((user) => getJsonUser(user));
};

export const getArray = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((brand) => getJson(brand));
  return { data, pagination };
};

export const setData = (data) => ({
  title: data.title,
  title_jpn: data.title_jpn,
  summary: data.summary,
  summary_jpn: data.summary_jpn,
  users: data.users,
  tags: data.tags,
  location : data.location,
  location_jpn : data.location_jpn,
  video_link : data.video_link,
  project_id : data.project.id,
  client_id : data.client.id,
  project_date : data.project_date,
  meta_description: data.meta_description,
  file_batch_id : data.file_batch_id,
});

export const setDataVerify = (data) => {
  const json = {};
  if (data.lang === "en") {
    json.verify_en = data.verify_en ? 0 : 1;
  } else if (data.lang === "jp") {
    json.verify_jpn = data.verify_jpn ? 0 : 1;
  }
  return json;
};

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
