import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import { getJsonUser } from "@/abstraction/resources/jobOfferResource";
import { getJson as getModel ,getModelJson } from "@/abstraction/resources/modelResource";
export const getJson = (data) => ({
  id: data.id,
  name: data.name,
  description: data.description,
  fee: data.fee,
  model: data.model && data.model.model ? getModel(data.model) : data.model && !!data.model.model ? getModelJson(data.model) : {} ,
  user: data.user ? getJsonUser(data.user) : {},
  request: data.request,
  begin_date: data.begin_date,
  end_date: data.end_date,
  created_at: data.created_at,
});

export const getArray = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((project) => getJson(project));
  return { data, pagination };
};

export const setData = (data) => ({
  name: data.name,
  description: data.description,
  fee: data.fee,
  model_id: data.request.model ? data.request.model.id : data.model.id,
  user_id: data.request.user ? data.request.user.id : data.user.id,
  request_id: data.request.id,
  begin_date: data.begin_date,
  end_date: data.end_date,
  created_at: data.created_at,
  id: data.id,
});

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
