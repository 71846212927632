import axios from "axios";
import url from "@/router/url";
import { getArray, setData } from "../resources/specialViewResource";
export default class specialViewRepository {
  async index() {
    let response = await axios.get(url("indexSpecialView"));
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async updateSpecialViews(userId, data) {
    let json = setData(data);
    await axios.put(url("updateSpecialViews", { user: userId }), json);
  }
}
