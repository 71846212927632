import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import {getJson} from "./modelResource";

export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta); 
    data = data.map((bookmark) => getJson(bookmark));
    return { data, pagination };
};

export const setQuery = (data) => {
    return SetQueriesObject(data);
};
