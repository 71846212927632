import axios from "axios";
import url from "@/router/url";
import { getArray, setQuery,setEmailData } from "../resources/contactResource";
import {getArrayEmail} from '../resources/jobOfferResource';
export default class ContactRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexContact"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async replyEmail(data) {
    const json = setEmailData(data);
    let response = await axios.post(url("replyEmail",{contact : data.contactId}),json);
    if (response.status === 200) {
      return getArrayEmail(response.data.data);
    }
  }
}
