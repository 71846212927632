import axios from "axios";
import url from "@/router/url";
import {getArray,setQuery} from "../resources/bookmarkResource";
export default class BookmarkRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexBookmark"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }
  async sync(modelId) {
   await axios.post(url("syncBookmark",{model:modelId}));
  }
}
