import axios from "axios";
import url from "@/router/url";
import {
  getArray,
  setQuery,
  setData,
  getJson,
} from "../resources/emailTemplateResource";
export default class emailTemplateRepository {
  async index(data) {
    let params = setQuery(data);
    let response = await axios.get(url("indexEmailTemplate"), { params });
    if (response.status === 200) {
      return getArray(response.data);
    }
  }

  async store(data) {
    let json = setData(data);
    let response = await axios.post(url("storeEmailTemplate"), json);
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }

  async delete(templateId) {
    await axios.delete(url("destroyEmailTemplate", { emailTemplate: templateId }));
  }

  async update(templateId, data) {
    let json = setData(data, true);
    let response = await axios.put(
      url("destroyEmailTemplate", { emailTemplate: templateId }),
      json
    );
    if (response.status === 200) {
      return getJson(response.data.data);
    }
  }
}
