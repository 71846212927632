import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import { objectToFormData } from "@/utils/objectToFormData";
export const getJson = (data) => ({
    id: data.id,
    thumbnail: data.thumbnail ? data.thumbnail : "https://via.placeholder.com/600x600",
    name: data.name,
    link: data.link,
    language_id: data.language_id ? Number(data.language_id) : null,
});

export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta); 
    data = data.map((brand) => getJson(brand));
    return { data, pagination };
};

export const setData = (data, isUpdate = false) => {
    let formData = {
        name: data.name,
        language_id: data.language_id,
        image: data.image, 
        link: data.link,
        file_batch_id: data.file_batch_id,
    };
    if (isUpdate) {
        formData["_method"] = "put";
    }
    return objectToFormData(formData);
};
export const setQuery = (data) => {
    return SetQueriesObject(data);
};
