import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
import { getModelJson } from "./modelResource";

export const getJsonJobOffer = (data) => ({
  begin_date: data.begin_date,
  audition_required: !!data.audition_required,
  created_at: new Intl.DateTimeFormat("en").format(new Date(data.created_at)),
  description: data.description,
  description_jpn: data.description_jpn,
  end_date: data.end_date,
  dates: data.jobOfferDates ? getJsonDates(data.jobOfferDates) : [],
  id: data.id,
  jobOfferResponsesCount: data.jobOfferResponsesCount ?? 0,
  questions: getArrayQuestions(data.questions),
  location: data.location,
  location_jpn: data.location_jpn,
  nearest_station: data.nearest_station,
  nearest_station_jpn: data.nearest_station_jpn,
  offer_salary: data.offer_salary,
  status: data.status ? data.status : "ongoing",
  title: data.title,
  title_jpn: data.title_jpn,
  unique_id: data.unique_id,
  updated_at: data.updated_at,
  thumbnail: data.thumbnail,
  has_required_files: !!data.has_required_files,
  required_files_label: data.required_files_label,
  required_files_max_count: data.required_files_max_count,
  image: data.image,
  user: data.user ? getJsonUser(data.user) : {},
  user_id: data.user_id,
  total_response_count: data.JobOfferResponses
    ? data.JobOfferResponses.length
    : 0,
  approved_response_count: data.JobOfferResponses
    ? data.JobOfferResponses.filter(
      (response) => response.jobOfferResponseStatus.verify === 1
    ).length
    : 0,
  reject_response_count: data.JobOfferResponses
    ? data.JobOfferResponses.filter(
      (response) => response.jobOfferResponseStatus.verify === 0
    ).length
    : 0,
});

export const getJsonDates = (dates) => {
  if (dates) {
    return dates.map((date) => ({
      id: date.id ? date.id : null,
      date: date.date,
      begin_time: date.begin_time ? date.begin_time.substr(0, 5) : null,
      end_time: date.end_time ? date.end_time.substr(0, 5) : null,
      require: !!date.require,
      created_at: date.created_at,
      job_offer_id: date.job_offer_id,
      status: date.status,
      // duplicate date for text
      text: date.date.slice(5).split("-").join("/")
    }));
  }
  return [];
};

export const setDataJobOffer = (data) => ({
  title: data.title,
  dates: setDates(data.dates),
  location: data.location,
  nearest_station: data.nearest_station,
  offer_salary: data.offer_salary,
  description: data.description,
  status: data.status,
  user_id: data.user.id,
  title_jpn: data.title_jpn,
  location_jpn: data.location_jpn,
  nearest_station_jpn: data.nearest_station_jpn,
  description_jpn: data.description_jpn,
  file_batch_id: data.file_batch_id,
  has_required_files: data.has_required_files ? 1 : 0,
  required_files_label: data.required_files_label,
  required_files_max_count: data.required_files_max_count,
  questions: data.questions ? setDataQuestion(data.questions) : [],
  audition_required: data.audition_required ? 1 : 0
});

export const setDataQuestion = (data) => {
  const questions = []
  data.map(qs => {
    if ((qs.type == "select" && qs.values || qs.type == "input") && qs.name) {
      questions.push({
        type: qs.type,
        name: qs.name,
        values: qs.values
      })
    }
  })
  return questions;
}
export const getArrayQuestions = (questions) => {
  if (!questions) {
    return [{ type: null, name: null, values: null, key: Math.random().toString(36).substring(2, 7) }];
  }
  return questions.map(item => getJsonQuestion(item));
}
export const getJsonQuestion = (question) => ({
  name: question.name,
  type: question.type,
  values: question.values
})

export const setDates = (dates) => {
  if (dates && dates.length) {
    let datesJson = [];
    datesJson = dates.map((date) => ({
      date: date.date,
      begin_time: date.begin_time,
      end_time: date.end_time,
      require: date.require ? 1 : 0,
      id: date.id
    }));
    return datesJson;
  }
  return [];
};

export const setDataResponse = (data) => ({
  email: data.email,
  shooting_date: data.shooting_date,
  shooting_dates: setDataShootingDates(data.shooting_dates),
  comment: data.comment,
  job_offer_response_status_id: data.job_offer_response_status_id,
  job_offer_unique_id: data.job_offer_unique_id,
  is_change_image: data.is_change_image,
  change_image_comment: data.is_change_image ? data.change_image_comment : null,
  nearest_station: data.nearest_station,
  file_batch_id: data.file_batch_id,
  question_values: setQuestionValues(data.question_values)
});

export const setQuestionValues = (data) => {
  return data.map(qs => ({
    name: qs.name,
    value: qs.value
  }))
}

export const setDataShootingDates = (dates) => {
  let shooting_dates = [];
  if (dates && dates.length) {
    for (const date of dates) {
      if (date && date.id && date.status) {
        const jsonDate = {
          job_offer_date_id: date.id,
          status: date.status,
        };
        shooting_dates.push(jsonDate);
      }
    }
    return shooting_dates;
  }
  return [];
};

export const setEmailData = (data) => {
  const email = {
    ids: data.ids,
    expect: data.expect,
  };
  if (data.email_template.id === -1) {
    email.message = data.message;
    email.subject = data.subject;
  } else {
    email.email_template_id = data.email_template.id;
  }
  return email;
};

export const setDataLinkManager = (data) => ({
  modelSelectedType: data.modelSelectedType,
  jobOfferResponses: data.jobOfferResponses,
  job_offer_id: data.job_offer_id,
  send_to_admin: data.send_to_admin
});

export const getArrayJobOffers = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((jobOffer) => getJsonJobOffer(jobOffer));
  return { data, pagination };
};

export const getArrayEmail = (data) => {
  return data.map((data) => getJsonEmail(data));
};

export const getJsonEmail = (data) => ({
  email: data.email,
  batch: data.batch,
  verify: data.verify,
  title: data.title,
  message: data.message,
  has_variable: data.has_variable,
  updated_at: data.updated_at,
  created_at: data.created_at,
});

export const getJsonUser = (data) => ({
  id: data.id,
  name: data.name,
  first_name: data.first_name,
  last_name: data.last_name,
  name_jpn: data.name_jpn,
  first_name_jpn: data.first_name_jpn,
  last_name_jpn: data.last_name_jpn,
  bio: data.bio,
  model_verified_at: data.model_verified_at,
  bio_jpn: data.bio_jpn,
  email: data.email,
  birthday: data.birthday,
  unique_id: data.unique_id,
  cell_phone: data.cell_phone,
  ethnic_id: data.ethnic_id,
  model_id: data.model_id,
  gender_id: data.gender_id,
  city: data.city,
  country_id: data.country_id,
  country: data.country
    ? {
      id: data.country.id,
      name: data.country.name,
      name_jpn: data.country.name_jpn,
      code: data.country.code,
    }
    : {},
  corporate: data.corporate,
  created_at: data.created_at,
  type: data.type,
  image: data.image,
  thumbnail: data.thumbnail,
  model: data.model ? getModelJson(data) : {},
  languagesWithLevel: data.languagesWithLevel
    ? getArrayLanguages(data.languagesWithLevel)
    : [],
  age: data.age,
  userRequestsCount: data.userRequestsCount
});

export const getArrayOfferResponse = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((response) => getJsonOfferResponse(response));
  return { data, pagination };
};

export const getArrayResponse = (data) => {
  return data.map((response) => getJsonOfferResponse(response));
};

export const getArrayLanguages = (data) => {
  return data.map((lang) => getJsonLanguage(lang));
};

export const getJsonLanguage = (data) => ({
  id: data.id,
  level_id: data.level_id,
  level_name: data.level_name,
  level_name_jpn: data.level_name_jpn,
  name: data.name,
  name_jpn: data.name_jpn,
});

export const getJsonOfferResponse = (data) => ({
  is_change_image: !!data.is_change_image,
  change_image_comment: data.change_image_comment,
  comment: data.comment,
  created_at: data.created_at,
  extera_job: data.extera_job,
  id: data.id,
  job_offer_id: data.job_offer_id,
  job_offer_response_status_id: data.job_offer_response_status_id,
  nearest_station: data.nearest_station,
  shooting_date: data.shooting_date,
  updated_at: data.updated_at,
  user_id: data.user_id,
  status: data.jobOfferResponseStatus ? getJsonResponseStatus(data.jobOfferResponseStatus) : {},
  user: data.user ? getJsonUser(data.user) : {},
  galleries: data.galleries,
  dates: data.jobOfferDates ? getJsonDates(data.jobOfferDates) : [],
  question_values: getArrayQuestionValues(data.question_values),
});

export const getArrayQuestionValues = (data) => {
  if (!data) { return []; }
  return data.map(qs => ({
    name: qs.name,
    value: qs.value,
    key: Math.random().toString(35).substring(2, 7)
  }))
}
export const getJsonStatusClient = (data) => ({
  id: data.id,
  status: getJsonResponseStatus(data.jobOfferResponseStatus),
})

export const getJsonResponseStatus = (data) => ({
  id: data.id,
  name: data.name,
  name_jpn: data.name_jpn,
  style: {
    bgColor: data.style.bgColor,
    color: data.style.color,
  },
  verification_permission: data.verification_permission,
  verify: !!data.verify,
});

export const setJobOfferLink = (data) => ({
  code: data.code,
});

export const setDataResponseStatus = (data) => ({
  job_offer_response_status_id: data.newResponseId,
});

export const setDataStatusManger = (data) => ({
  status: data.newStatus,
});

export const setDataStatusResponseSelected = (data) => ({
  job_offer_response_status_id: data.status_id,
  expect: data.expect,
  ids: data.ids,
});

export const getJsonOfferStatus = (data) => ({
  id: data.id,
  name: data.name,
  name_jpn: data.name_jpn,
  verification_permission: data.verification_permission,
  verify: data.verify,
  style: {
    color: data.style.color,
    bgColor: data.bgColor,
  },
});

export const getArrayOfferStatus = async (data) => {
  return data.map((status) => getJsonOfferStatus(status));
};

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
