import axios from "axios";
import url from "@/router/url";
import { getArray } from "../resources/genderResource";
export default class genderRepository {
    async index() {
        let response = await axios.get(url("indexGender"));
        if (response.status === 200) {
        return getArray(response.data);
        }
    }
}