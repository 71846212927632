import { SetQueriesObject, SetPagination } from "@/utils/setQueriesObject";
const options = {
  year: 'numeric', 
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};
const optionsTime = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};
const optionsDate = {
  year: 'numeric', 
  month: 'numeric',
  day: 'numeric',
};

export const getJson = (data) => ({
  id: data.id,
  title : data.title,
  name: data.name,
  company_name: data.company_name,
  email: data.email,
  cell_phone: data.cell_phone,
  message: data.message,
  created_at: new Intl.DateTimeFormat("en-US", options).format(new Date(data.created_at)),
  date: new Intl.DateTimeFormat("en-US", optionsDate).format(new Date(data.created_at)),
  time: new Intl.DateTimeFormat("en-US", optionsTime).format(new Date(data.created_at)),
  language_id: data.language_id,
  seen_at: new Intl.DateTimeFormat("en-US", options).format(new Date(data.seen_at)),
});

export const getArray = ({ data, meta }) => {
  const pagination = SetPagination(meta);
  data = data.map((item) => getJson(item));
  return { data, pagination };
};

export const setEmailData = (data) => {
  const email = {}
  if(data.email_template.id === -1 ){
      email.message= data.message;
      email.subject= data.subject;
  }else{
      email.email_template_id = data.email_template.id;
  }
  return email
}

export const setQuery = (data) => {
  return SetQueriesObject(data);
};
