export const getJson = (data) => ({
  id: data.id,
  name: data.name,
  description: data.description,
  verify: data.verify,
  color: data.style.color.length > 0 ? data.style.color : "red",
});

export const getArray = ({ data }) => {
  let items = data.map((status) => getJson(status));
  return { data: items };
};
